.google-sign-in-button{
    font-family: 'Roboto', sans-serif;
}

.google-sign-in-button-icon{
    margin: -13px 12px -12px -13px;
}

.app-bar.app-bar-small-screen .google-sign-in-button-icon{
    width: 29px;
    height: 29px;
}