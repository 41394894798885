.executors-not-found {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.executors-not-found-icon{
    width: 100px;
    opacity: 0.15;
}
