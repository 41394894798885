@keyframes record-preview-show {
    from {
        opacity: 0;
    }
    99%{
        opacity: 1;
    }
    to {
        opacity: 1;
    }
}

@keyframes record-preview-hide {
    from{
        opacity: 1;
    }
    0.1%{
        opacity: 0;
    }
    to{
        opacity: 0;
    }
}

.container-record-preview-visible{
    width: 250px;
    opacity: 1;
    transition: width 500ms ease, opacity 500ms step-end;
}

.container-record-preview-collapsed{
    width: 0;
    opacity: 0;
    transition: width 500ms ease, opacity 500ms step-start;
}

.record-preview-title{
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-black);
}

.record-preview-item{
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-gray);
}

.record-preview-close-button{
    font-size: 20px;
}

.record-preview-details{
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-blue);
}