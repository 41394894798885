.container-executors-map{
    display: flex;
    flex-grow: 1;
    width: 100%;
    min-height: 400px;
}

.list-container{
    box-shadow: var(--shadow-default);
    background-color: var(--content-background-color);
    border-radius: var(--spacing-0);
    display: flex;
    flex-direction: column;
    width: 350px;
    min-height: 400px;
    height: calc(100vh - 241px);
    overflow-y: auto;
}

.list-container.small-screen{
    width: 100%;
}

.toast-container-executors{
    position: absolute;
    bottom: 20px;
    right: 0;
}

.toast-text-executors{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
