.executors-list-item {
    font-size: 12px;
    padding: var(--spacing-2);
}

.executors-list-item:hover{
    cursor: pointer;
    background-color: var(--alice-blue);
}

.executors-list-item-icon{
    font-size: 26px;
    color: var(--color-text-gray);
}

.executors-list-item-icon:hover {
    color: var(--color-text-blue);
}

.executors-list-item-icon span{
    font-size: 10px;
}

.single-line-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}