:root {
    --snow-white: #ffffff;
    --white-smoke: #fafafa;
    --gallery: #eeeeee;
    --gainsboro: #e0e0e0;
    --total-black: #000000;
    --nero: #212121;
    --empress: #757575;
    --apple: #629c44;
    --lochmara: #27769d;
    --maya-blue: #64b5f6;
    --alice-blue: #e3f2fd;
    --cinnabar: #e53935;
}