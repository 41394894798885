.measurement-info-avg{
    color: var(--color-text-black);
    font-weight: 600;
    padding: var(--spacing-0);
    font-size: 24px;
}

.measurement-info-table th{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: var(--color-text-gray);
    padding: var(--spacing-0);
    min-width: 100px;
}

.measurement-info-table td{
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    color: var(--color-text-gray);
    padding: var(--spacing-0);
    min-width: 100px;
}

.measurement-info-details{
    color: var(--color-text-blue);
    font-size: 18px;
    font-weight: 600;
}

.measurement-info-details:hover{
    cursor: pointer;
    opacity: 0.8;
}