.link-input-text{
    color : var(--color-text-light-gray);
    font-size: 22px;
    font-weight: 300;
    text-align: center;
}

.rationale-text{
    color : var(--color-text-light-gray);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.toast-container-welcome{
    position: absolute;
    bottom: 20px;
    right: 0;
}

.toast-text-welcome{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}