.app-bar {
    top: 0;
    left: 0;
    width: 100%;
    min-width: var(--min-screen-width);
    height: var(--app-bar-height);
    background-color: var(--content-background-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-default);
    z-index: 100;
}

.app-bar-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max-screen-width);
    margin-left: auto;
    margin-right: auto;
}

.app-bar.app-bar-small-screen{
    height: auto;
    min-height: var(--app-bar-height);
    flex-direction: column;
    align-items: stretch;
}

.app-bar-link{
    font-size: 16px;
    color: var(--color-text-blue);
    white-space: nowrap;
    padding: var(--spacing-0) var(--spacing-1);
    border-radius: var(--spacing-1);
    background-color: transparent;
}

.accent{
    color: var(--color-text-red);
}

.app-bar-link.app-bar-small-screen{
    font-size: 14px;
    border-radius: 0;
}

.app-bar-link.active{
    font-weight: bold;
}

.app-bar-link:hover{
    background-color: var(--gallery);
    cursor: pointer;
}

.app-bar img {
    --margin: var(--spacing-2);
    margin: var(--margin);
    height: calc(var(--app-bar-height) - 2 * var(--margin));
}

.email-button .btn-group{
    min-width: 110px;
    max-width: calc(100vw - 290px - 300px - 24px - 24px - 85px);
}

.email-button.app-bar-small-screen .btn-group{
    min-width: 110px;
    max-width: calc(var(--min-screen-width) - 24px - 24px);
}

.email-button .dropdown-toggle-split.btn.btn-primary{
    min-width: 38px;
}

.email-button .btn{
    text-overflow: ellipsis;
    overflow: hidden;
}

.app-bar-menu-button{
    font-size: 14pt !important;
}

.app-bar-menu-button.btn{
    padding: 0 6px 2px 6px !important;
}

.app-bar.app-bar-small-screen .btn,
.app-bar.app-bar-small-screen .dropdown-menu{
    font-size: 10px;
}

.app-bar.app-bar-small-screen .dropdown-menu{
    min-width: 0;
}

.app-bar .dropdown-item{
    color: var(--color-text-blue);
}

.app-bar .dropdown-item-title{
    padding: 0.25rem 0.5rem;
    color: var(--color-text-black);
    font-weight: 600;
}

.app-bar .dropdown-item-active{
    font-weight: 600;
}

