.executors-info-window-list{
    font-size: 11px;
    font-weight: 400;
    max-height: 200px;
    max-width: 300px;
    overflow-y: auto;
}

.executors-info-window-list-item:hover{
    cursor: pointer;
    background-color: var(--alice-blue);
}

.executors-info-window-list-item-icon{
    font-size: 16px;
}

.single-line-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}