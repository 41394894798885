.awp-item{
    box-shadow: var(--shadow-default);
    background-color: var(--content-background-color);
    border-radius: var(--spacing-1);
}

.awp-list-title{
    color: var(--color-text-blue);
    font-size: 16px;
    font-weight: 600;
}

.awp-device-name{
    color: var(--color-text-blue);
    font-size: 12px;
    font-weight: 600;
}

.awp-description{
    color: var(--color-text-light-gray);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}