.popover-arrow {
    display: none !important;
}

.help-button-container{
    width: 32px;
    height: 32px;
    font-size: 22px;
    color: var(--color-text-gray);
}

.help-button-container:hover{
    border-radius: 50%;
    background: rgba(100, 181, 246, 0.25);
    color: var(--color-text-blue);
    cursor: pointer;
}