:root {
    --content-background-color: var(--snow-white);
    --window-background-color: var(--white-smoke);
    --color-text-black: var(--total-black);
    --color-text-white: var(--snow-white);
    --color-text-gray: var(--nero);
    --color-text-light-gray: var(--empress);
    --color-text-green: var(--apple);
    --color-text-blue: var(--lochmara);
    --color-text-red: var(--cinnabar);

    --spacing-0: 4px;
    --spacing-1: 8px;
    --spacing-2: 16px;
    --spacing-3: 24px;
    --spacing-4: 32px;
    --spacing-6: 48px;
    --spacing-8: 64px;

    --app-bar-height: 64px;

    --shadow-default: 0 0 5px rgba(0, 0, 0, 0.5);

    --min-screen-width: 380px;

    --max-screen-width: 1366px;
}

body {
    background-color: var(--snow-white);
    min-width: var(--min-screen-width);
}

.container-content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--app-bar-height));
    max-width: var(--max-screen-width);
    margin-left: auto;
    margin-right: auto;
}

.container-grow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.single-line{
    white-space:nowrap;
}

.ellipsize{
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.line-break{
    white-space: break-spaces;
    overflow-wrap: anywhere;
}

.whitespace{
    white-space: pre;
}

.clickable{
    font-weight: 500;
    color: var(--color-text-blue);
}

.clickable:hover{
    opacity: 0.6;
    cursor: pointer;
}

.overflow-y-clip{
    overflow-y: hidden;
}

.overflow-y-auto{
    overflow-y: auto;
}

.overflow-y-scroll{
    overflow-y: scroll;
}

.context-menu{
    font-size: 12px;
    color: var(--color-text-light-gray);
}

.fixed-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--content-background-color);
}

.fixed-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: var(--content-background-color);
}
