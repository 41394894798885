body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  color: var(--color-text-black);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:focus, .btn-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:focus, .btn-outline-primary.focus{
  box-shadow:none !important;
  outline:0 !important;
}

.btn-outline-primary:focus, .btn-outline-primary.focus{
  color: #007bff;
  background-color: #ffffff;
}

.mobile.btn-outline-primary:hover, .mobile.btn-outline-primary:active{
  color: #007bff;
  background-color: #ffffff;
}

