.fw-device-title{
    font-size: 16px;
    color: var(--color-text-blue);
    font-weight: 600;
}

.fw-step-item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fw-item-circle{
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: var(--color-text-light-gray);
    border-radius: 20px;
    border-style: solid;
    border-color: var(--color-text-light-gray);
    border-width: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fw-item-text{
    color: var(--color-text-black);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.fw-step-item-active .fw-item-circle{
    background: var(--color-text-blue);
    color: var(--color-text-white);
    border-width: 0;
}

.fw-step-item-active .fw-item-text{
    color: var(--color-text-blue);
    font-weight: 600;
}

.fw-simple-instruction-text{
    color: var(--color-text-light-gray);
    font-weight: 500;
    font-size: 20px;
}

.fw-simple-instruction-text-warning{
    color: var(--color-text-red);
    font-weight: 600;
    font-size: 16px;
}

.awp-fw-buttons-100 .btn{
    min-width: 100px;
}

.awp-fw-buttons-140 .btn{
    min-width: 140px;
}

.awp-fw-buttons-150 .btn{
    min-width: 150px;
}

.container-devices{
    min-height: 300px;
    height: 100%;
}

.device-list td {
    font-size: 14px;
    color: var(--color-text-blue);
    font-weight: 400;
}

.device-list tr:hover{
    cursor: pointer;
}

.device-list tr.device-item-active{
    background-color: var(--alice-blue);
}

.awp-fw-device-select-title{
    font-size: 14px;
    color: var(--color-text-black);
    font-weight: 600;
}

.awp-fw-device-select-description{
    font-size: 14px;
    color: var(--color-text-gray);
    font-weight: 400;
}

.awp-fw-device-info-table th,
.awp-fw-device-info-table td{
    padding: var(--spacing-1);
    border: 1px solid rgba(117, 117, 117, 0.3);
}

.awp-fw-device-info-table{
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text-black);
}

.awp-fw-device-info-table th{
    font-weight: 600;
    color: var(--color-text-blue);
}
