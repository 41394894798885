.calendar{
    flex-grow: 1;
    min-height: 450px;
    font-size: 12px;
    font-weight: 400;
}

.rbc-overlay {
    font-size: 12px;
    font-weight: 400;
    max-height: 145px;
    overflow-y: scroll;
}

.rbc-btn-group button[disabled] {
    opacity: 0.6;
}

.rbc-btn-group button[disabled]:hover {
    background-color: transparent;
}

.rbc-toolbar-label{
    font-weight: 600;
    color: var(--color-text-blue);
}

.rbc-toolbar-label:hover{
    cursor: pointer;
    opacity: 0.6;
}

.month-picker{
    position: absolute !important;
    left: calc((100% - 20rem) / 2);
}

.rmp-btn.single.active{
    background-color: var(--color-text-blue) !important;
}