.measurements-table{
    width: 100%;
}

.measurements-table th,
.measurements-table td{
    border: 2px solid var(--color-text-gray);
    padding: var(--spacing-1);
    text-align: center;
}

.measurements-table th{
    background-color: var(--color-text-light-gray);
}