.button-close{
    font-size: 40px;
    color: var(--color-text-light-gray);
}

.button-close:hover{
    color: var(--color-text-gray);
    cursor: pointer;
}

.container-measurement-info{
    height: 350px;
}