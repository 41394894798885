.awp-breadcrumbs {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text-gray);
    margin-right: var(--spacing-2);
}

.awp-breadcrumbs-item:hover {
    cursor: pointer;
    opacity: 0.8;
}

.awp-breadcrumbs-item.active-item {
    color: var(--color-text-light-gray);
}

.awp-breadcrumbs-item.active-item:hover {
    cursor: pointer;
    opacity: 0.8;
}