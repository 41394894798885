.error-screen {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.error-screen-stack {
    font-size: 10px;
    font-weight: 400;
    opacity: 0.5;
    white-space: break-spaces;
    text-align: center;
}

.error-screen-icon{
    width: 100px;
    opacity: 0.15;
}

.error-screen-link{
    color: var(--color-text-blue);
    font-weight: 600;
    opacity: 0.8;
}

.error-screen-link:hover{
    cursor: pointer;
    opacity: 0.5;
}

.error-screen-small-link{
    color: var(--color-text-blue);
    font-size: 12px;
    opacity: 0.8;
}

.error-screen-small-link:hover{
    cursor: pointer;
    opacity: 0.5;
}
