body{
    min-width: 1024px;
}

.loader-controls-table td {
    height: 50px;
}

textarea {
    resize: none;
}

.dropdown-toggle{
    width: 100%;
}

.progress{
    height: 31px;
}

.status {
    width: 100%;
    text-align: center;
}

.status-error{
    color: var(--color-text-red);
}

.side-panel{
    width: 450px;
}

.output-text-area  {
    font-size: 12px;
}