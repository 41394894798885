.executor-details-name{
    font-size: 16px;
    font-weight: bold;
    color: var(--color-text-black);
}

.executor-details-timestamp{
    font-size: 12px;
    color: var(--color-text-gray);
}

.executor-details{
    font-size: 14px;
    color: var(--color-text-black);
    max-height: 400px;
    overflow-y: auto;
}

.executor-details .title{
    font-weight: bold;
}