.toolbar-button-container{
    width: 32px;
    height: 32px;
    font-size: 22px;
    color: var(--color-text-gray);
}

.toolbar-button-container:hover{
    color: var(--color-text-blue);
    cursor: pointer;
}

.toolbar-button-container-disabled{
    color: var(--color-text-light-gray);
    opacity: 0.6;
}

.toolbar-button-container-disabled:hover{
    color: var(--color-text-light-gray);
    opacity: 0.6;
    cursor: pointer;
}

.toolbar-button-container-active{
    border-radius: 50%;
    background: rgba(100, 181, 246, 0.25);
}