.no-photo-placeholder {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.no-photo-placeholder-icon{
    width: 100px;
    opacity: 0.15;
}

