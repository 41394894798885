.table-measurement-info{
    height: 100%;
}

.table-measurement-info th,
.table-measurement-info td {
    border: 1px solid rgba(117, 117, 117, 0.3);
}

.table-measurement-info th{
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: var(--color-text-gray);
    padding: var(--spacing-0);
    min-width: 100px;
}

.table-measurement-info td{
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    color: var(--color-text-gray);
    padding: var(--spacing-0);
    min-width: 100px;
}

.table-mf-measurement-info{
    font-size: 18px;
    font-weight: 600;
    text-align: right;
    color: var(--color-text-gray);
    padding: var(--spacing-0);
    min-width: 300px;
}

.table-mf-measurement-info th{
    text-align: left;
}

.table-ipsm-measurement-info th,
.table-ipsm-measurement-info td{
    text-align: center;
    border: 1px solid rgba(117, 117, 117, 0.3);
    height: 40px;
}

.table-data{
    height: 100%;
}

.table-data th,
.table-data td {
    border: 1px solid rgba(117, 117, 117, 0.3);
}

.table-data th{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: var(--color-text-gray);
    padding: var(--spacing-0);
    min-width: 100px;
}

.table-data td:first-child{
    text-align: left;
    font-weight: 500;
}

.table-data td{
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: var(--color-text-gray);
    padding: var(--spacing-0);
    min-width: 100px;
}