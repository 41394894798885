.view-container{
    box-shadow: var(--shadow-default);
    background-color: var(--content-background-color);
    border-radius: var(--spacing-1);
    display: flex;
    flex-direction: column;
}

.view-title{
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-blue);
}

.view-date-time{
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-light-gray);
}

.view-action{
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-blue);
}

.view-action:hover{
    cursor: pointer;
    opacity: 0.8;
}

.view-item-title{
    font-weight: 600;
    color: var(--color-text-black);
}

.view-item-description{
    font-weight: 500;
    color: var(--color-text-light-gray);
}

.view-item-name{
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-black);
}

.view-enlarge{
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-blue);
}

.view-enlarge:hover{
    cursor: pointer;
    opacity: 0.8;
}