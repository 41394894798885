.fw-select-modal{
    min-width: 967px;
}

.dropdown-toggle{
    width: auto !important;
}

.fw-table {
    table-layout: fixed;
    width: 100%;
}

.fw-table td, th {
    text-align: center;
    height: 50px;
    vertical-align: center;
}