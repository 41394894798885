.not-location {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.not-location-icon{
    width: 100px;
    opacity: 0.15;
}
