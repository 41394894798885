.not-found {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.not-found-icon{
    width: 100px;
    opacity: 0.15;
}

.not-found-link{
    color: var(--color-text-blue);
    font-weight: 600;
    opacity: 0.8;
}

.not-found-link:hover{
    cursor: pointer;
    opacity: 0.5;
}
