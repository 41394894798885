.fw-instruction-text{
    color : var(--color-text-light-gray);
    font-size: 22px;
    font-weight: 300;
    text-align: center;
}

.fw-warning-text {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
}