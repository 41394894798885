.loader-normal{
    width: 48px;
    height: 48px;
}

.loader-small{
    width: 20px;
    height: 20px;
}

.loader-message {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.loader-message-danger{
    font-size: 14px;
    font-weight: 600;
    opacity: 0.8;
    color: var(--color-text-red);
}