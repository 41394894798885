.body-awp{
    --min-screen-width: 640px;
}

.container-records{
    height: 300px;
    max-height: 100%;
}

.instruction-text{
    color : var(--color-text-light-gray);
    font-size: 22px;
    font-weight: 300;
    text-align: center;
}

.small-instruction-text{
    color : var(--color-text-light-gray);
    font-style: italic;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
}