.map-info-window-list{
    font-size: 11px;
    font-weight: 400;
    max-height: 200px;
    overflow-y: scroll;
}

.map-info-window-list-item:hover{
    cursor: pointer;
    background-color: var(--alice-blue);
}

.map-info-window-list-item-icon{
    font-size: 16px;
}