.button-close{
    font-size: 40px;
    color: var(--color-text-light-gray);
}

.button-close:hover{
    color: var(--color-text-gray);
    cursor: pointer;
}

.toast-container-viewer{
    position: absolute;
    top: 100px;
    right: 0;
}

.toast-text-viewer{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}