.archive-list{
    font-size: 12px;
    color: var(--color-text-black);
    min-height: 100%;
}

.archive-list td {
    padding: 0;
}

.archive-list tr.archive-item-active{
    background-color: var(--alice-blue);
}

.archive-list th,
.archive-list td{
    color: var(--color-text-blue);
    font-weight: 400;
}

.archive-list th:first-child,
.archive-list td:first-child{
    width: 100%;
}

.archive-list th:not(:first-child),
.archive-list td:not(:first-child){
    white-space: nowrap;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
}

.archive-list th:hover{
    cursor: pointer;
}

.archive-list th .sort-type,
.archive-list td .archive-item-data{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.sort-icon {
    width: 24px;
    height: 24px;
    font-size: 18px;
    margin: 0 var(--spacing-1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.sort-icon:hover{
    background-color: var(--gainsboro);
    border-radius: 12px;
}

.item-type-icon {
    width: 24px;
    height: 24px;
    font-size: 18px;
    margin: 0 var(--spacing-1);
    display: flex;
    justify-content: center;
    align-items: center;
}