.measurement-details-container {
    max-height: 60vh;
    height: 500px;
    margin-bottom: 24px;
    margin-left: 40px;
    margin-right: 40px;
}


.carousel-indicators{
    margin: 0 !important;
}

.carousel-control-prev{
    width: 32px !important;
}

.carousel-control-next{
    width: 32px !important;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators  {
    filter: invert(100%);
}